<!--
  个人挂靠
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="个人挂靠" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="form"
          :rules="rules"
          size="small"
          label-width="130px"
          label-position="left">
          <div class="module_box">
            <div class="module_box_header">
              <p>挂靠信息</p>
            </div>
            <div class="module_box_main">
              <el-form-item
                label="头像"
                prop="avatarUrl">
                <upload-files
                  :showFileList="false"
                  :maxSize="4"
                  accept="image/png, image/jpg, image/jpeg"
                  :readonly="true"
                  :showTip="false"
                  listType="custom"
                  customClassName="logourl_upload"
                  @successUpload="(file, res) => form.avatarUrl = res.data.url"
                  title="点击上传">
                  <el-image
                    v-if="form.avatarUrl"
                    style="width: 120px; height: 120px;"
                    :src="form.avatarUrl"
                    fit="cover">
                  </el-image>
                  <div v-else class="uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                </upload-files>
              </el-form-item>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="姓名"
                    prop="username">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.username"
                      placeholder="填写姓名全名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="身份类别"
                    prop="identityCategory">
                    <el-select
                      style="width: 100%;"
                      v-model="form.identityCategory"
                      placeholder="请选择身份类别">
                      <el-option
                        v-for="item in staticData.sflb"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                    <el-input maxlength="50" show-word-limit
                      v-if="form.identityCategory === '其他'"
                      style="width: 100%;margin-top:10px;"
                      v-model="form.identityCategoryOther"
                      placeholder="请注明其他身份类别">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 去掉了组织类别 新增行业领域 -->
              <el-form-item
                label="行业领域"
                prop="serviceTrade">
                <el-checkbox-group v-model="form.serviceTrade">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in industrySectorList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="服务方向"
                prop="serviceDirection">
                <el-checkbox-group v-model="form.serviceDirection">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in staticData.fwfx"
                        :key="item"
                        :label="item">
                        {{ item }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="产业特长"
                prop="industryExpertise">
                <el-checkbox-group v-model="form.industryExpertise">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in technicalList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="身份证号"
                    prop="idCard">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.idCard"
                      placeholder="请填写身份证号">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="出生年月"
                    prop="birthDate">
                    <el-date-picker
                      style="width: 100%;"
                      v-model="form.birthDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="请选择出生年月">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="工作单位"
                    prop="workUnit">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.workUnit"
                      placeholder="请填写工作单位全称；如无，请填无">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="职务"
                    prop="jobTitle">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.jobTitle"
                      placeholder="请填写职务">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="最高学历"
                    prop="highestEducation">
                    <el-input maxlength="50" show-word-limit
                      v-model="form.highestEducation"
                      placeholder="请输入最高学历">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                 <el-form-item
                    label="联系电话"
                    prop="contactNumber">
                    <el-input maxlength="11" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactNumber"
                      placeholder="请输入联系电话">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="联系邮箱"
                    prop="contactEmail">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactEmail"
                      placeholder="请输入联系邮箱">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="联系地址"
                    prop="contactAddressArray">
                    <el-cascader
                      style="width: 100%;"
                      placeholder="请选择联系地址"
                      v-model="form.contactAddressArray"
                      :options="staticData.cities">
                    </el-cascader>
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%; margin-top:10px;"
                      v-model="form.contactAddress"
                      placeholder="请输入详细地址">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item
                label="性别"
                prop="gender">
                <el-radio-group v-model="form.gender">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="服务案例"
                prop="serviceCase">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="典型服务案例1-2个"
                  v-model="form.serviceCase">
                </el-input>
              </el-form-item>
              <el-form-item
                label="个人简介"
                prop="personalProfile">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="详细的个人简介，包含个人教育经历、工作经历、工作取得的成效等"
                  v-model="form.personalProfile">
                </el-input>
              </el-form-item>
              <el-form-item
                label="职称/资格认证"
                prop="positionalTitleText">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="请输入职称/资格认证"
                  v-model="form.positionalTitleText">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <!-- 这里搜索的只是机构 -->
          <div class="module_box">
            <div class="module_box_header">
              <p>选择挂靠单位</p>
            </div>
            <div class="module_box_main">
              <el-form-item
                label="选择挂靠机构"
                prop="organizationId">
                <el-select
                  v-model="form.organizationId"
                  filterable
                  remote
                  placeholder="请输入机构名称搜索"
                  :remote-method="searchOrg"
                  :loading="selectLoading"
                  @change="curOrgIdChange">
                  <el-option
                    v-for="item in orgList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="机构简介">
                <span v-if="curOrg.description">{{ curOrg.description }}</span>
                <span v-else>无</span>
              </el-form-item>
              <el-form-item
                label="机构头像">
                <el-image
                  v-if="curOrg.logoUrl"
                  style="width: 120px; height: 120px;"
                  :src="curOrg.logoUrl"
                  fit="cover">
                </el-image>
                <span v-else>无</span>
              </el-form-item>
            </div>
          </div>
        </el-form>

        <div class="btn_box tw-mt-30px">
          <el-button
            style="margin-right: 20px;"
            type="primary"
            size="small"
            @click="submitForm"
            :loading="btnLoading"
            :disabled="!agree">
            提交申请
          </el-button>
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}诚信承诺书.pdf`" target="_blank">《诚信承诺书》</a>
            </p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    // 验证详细地址是否输入
    const contactAddress = (rule, value, callback) => {
      if (this.form.contactAddress === '') {
        callback(new Error('请输入详细地址'))
      } else {
        callback()
      }
    }
    // 身份类别选择其他时 进行验证
    const sflbOther = (rule, value, callback) => {
      if (value === '其他' && this.form.identityCategoryOther === '') {
        callback(new Error('请注明其他身份类别'))
      } else {
        callback()
      }
    }
    return {
      loading: false, // 页面loading
      selectLoading: false, // 搜索机构loading
      btnLoading: false, // 提交按钮loading
      agree: false, // 是否同意条款
      /* form: {
        avatarUrl: 'http://192.168.1.175:9919/media/c8a879c5be2ec8bdb5aefa1cce579293',
        username: '17351赵',
        identityCategory: '技术专家',
        identityCategoryOther: '',
        serviceTrade: [
          '87b05e509548f751834ce434f18f831b',
          '30813b3570c49000b5f2319893f0f01d',
          'c253a93fa36197e0a97479863114f326'
        ],
        industryExpertise: [
          '5e79b1151fb70242bd5d3f85b3ef1f90',
          '66f8ee9b8d7b87ccc2f52378803239d2'
        ],
        serviceDirection: [
          '技术转移',
          '科技咨询',
          '综合科技服务'
        ],
        idCard: '420922199710136815',
        birthDate: '2021-08-27',
        workUnit: '优聘',
        jobTitle: '程序员',
        highestEducation: '大专',
        contactNumber: '17371278551',
        contactEmail: '1159902844@qq.com',
        contactAddress: '',
        gender: 1,
        serviceCase: '1、造过核弹\n2、对过核弹',
        personalProfile: '非常厉害',
        positionalTitleText: '初级程序员',
        organizationId: ''
      }, */
      form: {
        // 用户信息
        avatarUrl: '', // 机构头像
        username: '', // 姓名
        identityCategory: '', // 身份类别
        identityCategoryOther: '', // 身份类别，其他
        serviceTrade: [], // 行业领域
        industryExpertise: [], // 产业领域
        serviceDirection: [], // 服务方向
        idCard: '', // 用户身份证号
        birthDate: '', // 出生年月
        workUnit: '', // 工作单位
        jobTitle: '', // 职务
        highestEducation: '', // 最高学历
        contactNumber: '', // 联系电话
        contactEmail: '', // 联系邮箱
        contactAddressArray: [], // 联系地址，省市区选择器
        contactAddress: '', // 联系地址详细地址
        gender: 0, // 性别
        serviceCase: '', // 服务案例
        personalProfile: '', // 个人简介
        positionalTitleText: '', // 职称/所或资格认证
        organizationId: '' // 挂靠单位
      },
      rules: {
        avatarUrl: [
          { required: true, message: '请输入机构头像', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写姓名全名', trigger: 'blur' }
        ],
        identityCategory: [
          { required: true, message: '请选择身份类别', trigger: 'change' },
          { validator: sflbOther, trigger: 'blur' }
        ],
        serviceTrade: [
          { required: true, message: '请选择行业领域', trigger: 'change' }
        ],
        industryExpertise: [
          { required: true, message: '请选择产业特长', trigger: 'change' }
        ],
        serviceDirection: [
          { required: true, message: '请选择服务方向', trigger: 'change' }
        ],
        idCard: [
          { required: true, message: '请填写身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确身份证格式', trigger: 'blur' }
        ],
        birthDate: [
          { required: true, message: '请选择出生年月', trigger: 'change' }
        ],
        workUnit: [
          { required: true, message: '请填写工作单位全称；如无，请填无', trigger: 'blur' }
        ],
        jobTitle: [
          { required: true, message: '请填写职务', trigger: 'blur' }
        ],
        highestEducation: [
          { required: true, message: '请填写最高学历', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确邮箱格式', trigger: 'blur' }
        ],
        contactAddressArray: [
          { required: true, message: '请选择联系地址', trigger: 'change' },
          { validator: contactAddress, trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        serviceCase: [
          { required: true, message: '请填写服务案例', trigger: 'blur' }
        ],
        personalProfile: [
          { required: true, message: '请填写个人简介', trigger: 'blur' }
        ],
        positionalTitleText: [
          { required: true, message: '请输入职称/所获资格认证', trigger: 'blur' }
        ],
        organizationId: [
          { required: true, message: '请选择挂靠机构', trigger: 'blur' }
        ]
      },
      // 机构列表
      orgList: [],
      // 当前所选机构对象
      curOrg: {}
    }
  },
  computed: {
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    // 根据关键词来搜索结构
    getKeywordsDownOrgList (value) {
      this.selectLoading = true
      api.getKeywordsDownOrgList({
        keywords: value
      }).then(res => {
        if (res.data.code === 0) {
          console.log('机构列表', res.data.data)
          this.orgList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.selectLoading = false
      })
    },

    // 搜索机构
    searchOrg (value) {
      if (value !== '') {
        this.getKeywordsDownOrgList(value)
      } else {
        this.orgList = []
      }
    },
    // 当前所选择机构id变化
    curOrgIdChange (value) {
      if (value && this.orgList.length) {
        const org = this.orgList.find(item => {
          return item.id === value
        })
        this.curOrg = org || {}
      }
    },

    // 表单验证
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          // 申请服务机构
          this.personOrgRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    personOrgRegister () {
      let { serviceTrade, serviceDirection, industryExpertise, contactAddressArray } = this.form
      // 身份类别如果选择的是其他的话，则使用input中的
      const identityCategory = this.form.identityCategory === '其他' ? this.form.identityCategoryOther : this.form.identityCategory
      // 行业领域转为逗号分隔的字符串
      serviceTrade = serviceTrade ? serviceTrade.join(',') : ''
      // 服务方向转为逗号分隔字符串
      serviceDirection = serviceDirection ? serviceDirection.join(',') : ''
      // 产业特长转为逗号分隔字符串
      industryExpertise = industryExpertise ? industryExpertise.join(',') : ''
      const [addressProvince, addressCity, addressCounty] = [...contactAddressArray]
      const formData = {
        ...this.form,
        identityCategory,
        serviceTrade,
        serviceDirection,
        industryExpertise,

        addressProvince,
        addressCity,
        addressCounty
      }
      this.btnLoading = true
      api.personOrgRegister({
        ...formData
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('申请成功')
          this.$router.replace('/personal-info')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0px;
    .main_content {
      width: 1100px;
      margin: 0 auto;
      .module_box {
        .module_box_header {
          display: flex;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        .module_box_main {
          padding: 0 50px 0 20px;
        }
        &+.module_box {
          margin-top: 40px;
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        padding-left: 150px;

      }
      .logourl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader-icon {
          width: 120px;
          height: 120px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & i {
            font-size: 28px;
            color: #8c939d;
          }
        }
      }
      .codeurl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 220px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & > i {
            font-size: 40px;
            color: #9AA0A9;
          }
          & > p {
            width: 200px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            margin-top: 15px;
          }
        }
      }
      .checkbox_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 450px;
      }
    }
  }
}
</style>
